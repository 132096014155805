.App {
  text-align: center;
  display: flex;
  flex-flow: column;
  align-items: center;
}

.App-logo {
  pointer-events: none;
  width: 280px;
  min-height: 88px;
  object-fit: cover;
}


.App-header {
  margin-top: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 1vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

header h2 {
  border-top: 1px solid #777;
  padding: 20px 0 0 0; 
  width: 100%;
}

.menu{
  padding: 1vmin 0;
}

a {
  color: #ffcc00;
}

ul {
  font-size: calc(10px + 1vmin);
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  margin: 0px 30px 10px;
  box-sizing: border-box;
  max-width: 50vmax;
}

li {
  border: 2px solid #ffcc00;
  border-radius: 15px;
  margin: 2px 0;
  display: flex;
  overflow: hidden;
}

li:hover {
  color: #fff;
  border-color: #dc9123;
  box-shadow: 2px 2px 10px rgba(0,0,0,0.9);
}

.disabled{
  opacity: 0.4;
  filter: grayscale(100%);
  pointer-events: none;
}

li a {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 10px;
  text-decoration: none;
}

li a:hover {
  background: #dc9123;
  color: #fff;
}

small {
  color: #666;
}